import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ErrorPopupProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    message: string;
    details?: string;
}

const ErrorPopup = ({ open, onClose, title = 'Error', message, details }: ErrorPopupProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    backgroundColor: '#252538',
                    color: 'white',
                    borderRadius: '12px',
                    padding: '8px',
                    maxWidth: '500px'
                }
            }}
        >
            <DialogTitle sx={{ color: '#ff6b6b', display: 'flex', alignItems: 'center', gap: 1 }}>
                <ErrorOutlineIcon color="error" />
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ color: 'rgba(255, 255, 255, 0.9)', mb: 2 }}>
                    {message}
                </Typography>

                {details && (
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            borderRadius: '4px',
                            padding: '12px',
                            maxHeight: '200px',
                            overflow: 'auto',
                            fontSize: '0.85rem',
                            fontFamily: 'monospace',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            color: 'rgba(255, 255, 255, 0.7)'
                        }}
                    >
                        {details}
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button
                    onClick={onClose}
                    sx={{
                        bgcolor: '#E0B1F1',
                        color: '#252538',
                        fontWeight: 'bold',
                        '&:hover': {
                            bgcolor: '#d396ed',
                        }
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorPopup; 