'use client';

import { Box, Typography, Button, Paper, Chip, alpha, Divider } from '@mui/material';
import { ChatLogMessage, Offer } from '../../../shared/models';
import { useEffect, useRef, useState, useCallback } from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';
import LocalOffer from '@mui/icons-material/LocalOffer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerIcon from '@mui/icons-material/Timer';
import TrendingDown from '@mui/icons-material/TrendingDown';
import PercentIcon from '@mui/icons-material/Percent';
import dayjs from 'dayjs';

// Define theme colors for consistency
const themeColors = {
    primary: '#E0B1F1',
    primaryDark: '#d49fe0',
    darkBg: '#2c2c54',
    accent: '#8a5cdb',
    textLight: '#ffffff',
    textMuted: 'rgba(255, 255, 255, 0.7)',
    border: 'rgba(255, 255, 255, 0.15)',
    cardBg: 'rgba(255, 255, 255, 0.08)',
    cardHoverBg: 'rgba(255, 255, 255, 0.12)',
    messageBg: 'rgba(138, 92, 219, 0.1)',
    green: '#4CAF50',
    lightGreen: '#8BC34A',
    blue: '#2196F3',
    lightBlue: '#90CAF9',
    amber: '#FFC107',
}

interface SystemMessage {
    Message: string;
    CreatedAt: Date;
    Link?: string;
}

export interface ChatMessageWithOffer extends ChatLogMessage {
    offer?: Offer;
}

interface ChatMessagesProps {
    leftMessages: ChatMessageWithOffer[];
    rightMessages: ChatMessageWithOffer[];
    systemMessages?: SystemMessage[];
    className?: string;
    maxMessages?: number;
    onScrollToBottomRef?: (scrollToBottom: () => void) => void;
    showAllMessages?: boolean;
}

// Helper function to calculate discount percentage
const calculateDiscountPercentage = (basePrice: number, estimatedPrice: number) => {
    if (!basePrice || !estimatedPrice || basePrice <= 0) return 0;
    const discount = basePrice - estimatedPrice;
    return Math.round((discount / basePrice) * 100);
};

// Helper to get the category of an offer
const getOfferCategory = (offer: Offer) => {
    if (offer.IsNoMinimumSpend) return 'noMinimumSpend';
    if (offer.IsPrivateSpace) return 'private';
    if (offer.IsSemiPrivateSpace) return 'semiPrivate';
    return 'regular';
};

// Get category label and icon
const getCategoryInfo = (categoryName: string) => {
    switch (categoryName) {
        case 'noMinimumSpend':
            return {
                label: 'No Minimum Spend',
                icon: <CheckCircleIcon sx={{ color: 'lightgreen' }} />,
                color: 'lightgreen',
                bgColor: 'rgba(144, 238, 144, 0.1)'
            };
        case 'private':
            return {
                label: 'Private Section',
                icon: <MeetingRoomIcon sx={{ color: '#E0B1F1' }} />,
                color: '#E0B1F1',
                bgColor: 'rgba(224, 177, 241, 0.1)'
            };
        case 'semiPrivate':
            return {
                label: 'Semi-Private Section',
                icon: <GroupsIcon sx={{ color: '#90CAF9' }} />,
                color: '#90CAF9',
                bgColor: 'rgba(144, 202, 249, 0.1)'
            };
        default:
            return {
                label: 'Standard Offer',
                icon: <LocalOffer sx={{ color: 'white' }} />,
                color: 'white',
                bgColor: 'rgba(255, 255, 255, 0.1)'
            };
    }
};

// Helper function to check if the offer has a flexible cancellation policy
const hasFlexibleCancellation = (offer: Offer) => {
    return offer.IsNoMinimumSpend || (offer.DepositPercentage && offer.DepositPercentage < 25);
};

export default function ChatMessages({
    leftMessages,
    rightMessages,
    systemMessages = [],
    className = '',
    maxMessages,
    onScrollToBottomRef,
    showAllMessages: initialShowAllMessages = false
}: ChatMessagesProps) {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const [showAllMessages, setShowAllMessages] = useState(initialShowAllMessages);

    // Function to scroll to bottom of chat
    const scrollToBottom = useCallback(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, []);

    // Auto-scroll when messages change
    useEffect(() => {
        scrollToBottom();
    }, [leftMessages, rightMessages, scrollToBottom]);

    // Register scroll function with parent component if needed
    useEffect(() => {
        if (onScrollToBottomRef) {
            onScrollToBottomRef(scrollToBottom);
        }
    }, [onScrollToBottomRef, scrollToBottom]);

    if ((!leftMessages || leftMessages.length === 0) && (!rightMessages || rightMessages.length === 0)) {
        if (!systemMessages || systemMessages.length === 0) {
            return (
                <Box className="flex flex-col items-center justify-center p-8" sx={{ height: '100%' }}>
                    <Box className="text-center space-y-4">
                        <Typography
                            variant="h6"
                            className="text-gray-300"
                        >
                            No messages yet
                        </Typography>
                    </Box>
                </Box>
            );
        }

        return (
            <Box className={`flex flex-col space-y-3 overflow-y-auto overflow-x-hidden ${className}`} sx={{ height: '100%', width: '100%', maxWidth: '100%' }}>
                {systemMessages.map((message, index) => (
                    <Box
                        key={`system-${index}`}
                        className="flex justify-center w-full"
                    >
                        <Typography
                            component={message.Link ? 'a' : 'div'}
                            href={message.Link}
                            variant="body2"
                            className={`
                                text-gray-300 text-sm font-medium px-3 py-1.5 
                                bg-gray-800/50
                                ${message.Link ? 'cursor-pointer hover:text-[#E0B1F1] hover:underline' : ''}
                            `}
                        >
                            {message.Message}
                        </Typography>
                    </Box>
                ))}
                <div ref={messagesEndRef} style={{ float: 'left', clear: 'both' }} />
            </Box>
        );
    }

    // Combine and sort messages
    const chatMessages = [
        ...leftMessages.map(m => ({ type: 'chat' as const, ...m })),
        ...rightMessages.map(m => ({ type: 'chat' as const, ...m }))
    ]
        .sort((a, b) => new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime())
        .slice(0, showAllMessages ? undefined : maxMessages);

    const allMessages = [
        ...chatMessages,
        ...systemMessages.map(m => ({ type: 'system' as const, ...m }))
    ]
        .sort((a, b) => {
            const dateA = a.type === 'chat' ? a.CreatedAt : a.CreatedAt;
            const dateB = b.type === 'chat' ? b.CreatedAt : b.CreatedAt;
            return new Date(dateA).getTime() - new Date(dateB).getTime();
        });

    return (
        <Box
            className={`flex flex-col space-y-4 overflow-y-auto overflow-x-hidden ${className}`}
            sx={{
                height: '100%',
                flex: 1,
                width: '100%',
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'column',
                '&::-webkit-scrollbar': {
                    width: '6px',
                    height: '0px', // Hide horizontal scrollbar
                },
                '&::-webkit-scrollbar-track': {
                    background: 'rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(224, 177, 241, 0.4)',
                    borderRadius: '8px',
                    '&:hover': {
                        background: 'rgba(224, 177, 241, 0.6)',
                    }
                }
            }}
        >
            {!showAllMessages && (leftMessages.length + rightMessages.length) > maxMessages && (
                <Button
                    variant="text"
                    onClick={() => setShowAllMessages(true)}
                    className="text-gray-500 hover:text-[#E0B1F1] normal-case text-sm"
                    sx={{
                        minWidth: 'auto',
                        width: 'fit-content',
                        margin: '0 auto',
                    }}
                >
                    Show {leftMessages.length + rightMessages.length - maxMessages} earlier messages
                </Button>
            )}

            {/* Main messages container - takes flex: 1 to fill available space */}
            <Box
                ref={messagesContainerRef}
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    pb: 2,
                    px: { xs: 0.5, sm: 1 }, // Add some horizontal padding
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: '100%',
                    gap: 3 // Increased gap between message groups
                }}
            >
                {allMessages.map((message, index) => {
                    if (message.type === 'system') {
                        return (
                            <Box
                                key={`system-${index}`}
                                className="flex justify-center w-full"
                            >
                                <Typography
                                    component={message.Link ? 'a' : 'div'}
                                    href={message.Link}
                                    variant="body2"
                                    className={`
                                        text-gray-300 text-sm font-medium px-3 py-1.5 
                                        bg-gray-800/50
                                        ${message.Link ? 'cursor-pointer hover:text-[#E0B1F1] hover:underline' : ''}
                                    `}
                                >
                                    {message.Message}
                                </Typography>
                            </Box>
                        );
                    }

                    // Skip rendering if no message content and no offer
                    if (!message.Message && !message.MessageWithoutEmailOrPhoneNumbers && !message.FileUrl && !message.offer) {
                        return null;
                    }

                    const isLeftMessage = leftMessages.some(m => m.ID === message.ID);
                    const hasMessageContent = message.Message || message.MessageWithoutEmailOrPhoneNumbers;
                    const hasOffer = !!message.offer;

                    // Check if current message is from a different sender than previous one
                    // This helps us add more spacing when sender changes
                    const prevMessage = index > 0 ? allMessages[index - 1] : null;
                    const isNewSenderGroup =
                        prevMessage &&
                        prevMessage.type === 'chat' &&
                        leftMessages.some(m => m.ID === prevMessage.ID) !== isLeftMessage;

                    return (
                        <Box
                            key={message.ID || index}
                            className={`flex w-full ${isLeftMessage ? 'justify-start' : 'justify-end'}`}
                            sx={{
                                mt: isNewSenderGroup ? 2 : 0.75,
                                // Increase right margin for left messages to create more space
                                mr: isLeftMessage && hasOffer ? { xs: 2, sm: 4, md: 6 } : 0
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: { xs: hasOffer ? '95%' : '85%', sm: hasOffer ? '90%' : '75%' },
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: hasOffer ? 1.5 : 0
                                }}
                            >
                                {/* Message bubble */}
                                {hasMessageContent && (
                                    <Box
                                        className={`
                                                px-4 py-3 shadow-md
                                                ${isLeftMessage
                                                ? 'bg-[#E0B1F1] text-gray-900 rounded-tl-none rounded-tr-2xl rounded-bl-2xl rounded-br-2xl'
                                                : 'bg-gradient-to-br from-[#2c2c54] to-[#3c3c74] text-white rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl rounded-br-none'
                                            }
                                            `}
                                        sx={{
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word'
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            className="text-sm leading-relaxed break-words whitespace-pre-wrap"
                                            sx={{
                                                overflow: 'hidden',
                                                wordBreak: 'break-word'
                                            }}
                                        >
                                            {isLeftMessage && message.MessageWithoutEmailOrPhoneNumbers
                                                ? message.MessageWithoutEmailOrPhoneNumbers
                                                : message.Message}
                                        </Typography>

                                        {/* File attachment */}
                                        {message.FileUrl && message.FileName && (
                                            <a
                                                href={message.FileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`
                                                    block mt-2 text-sm font-medium
                                                    ${isLeftMessage
                                                        ? 'text-purple-800 hover:text-purple-900'
                                                        : 'text-[#E0B1F1] hover:text-[#f0c1ff]'
                                                    }
                                                    underline
                                                `}
                                            >
                                                📎 {message.FileName}
                                            </a>
                                        )}

                                        {/* Timestamp */}
                                        <Typography
                                            variant="caption"
                                            className={`
                                                block mt-1 text-xs
                                                ${isLeftMessage ? 'text-gray-700' : 'text-gray-200'}
                                            `}
                                        >
                                            {new Date(message.CreatedAt).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                                timeZone: 'UTC'
                                            })}
                                        </Typography>
                                    </Box>
                                )}

                                {/* Offer Card - Display if message has an offer */}
                                {message.offer && (
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            borderRadius: '16px',
                                            overflow: 'hidden',
                                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                                            '&:hover': {
                                                transform: 'translateY(-2px)',
                                                boxShadow: 6
                                            },
                                            bgcolor: isLeftMessage ? 'white' : 'rgba(32, 32, 64, 0.9)',
                                            border: `1px solid ${isLeftMessage ? 'rgba(0,0,0,0.1)' : 'rgba(224, 177, 241, 0.3)'}`,
                                            width: '100%',
                                            maxWidth: { xs: '100%', sm: '400px' }
                                        }}
                                    >
                                        {/* Offer Header with type badge */}
                                        <Box
                                            sx={{
                                                px: 2,
                                                py: 1.5,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: `1px solid ${isLeftMessage ? 'rgba(0,0,0,0.06)' : 'rgba(255,255,255,0.1)'}`,
                                                bgcolor: isLeftMessage ? 'rgba(248, 249, 250, 1)' : 'rgba(44, 44, 84, 0.95)',
                                                width: '100%',
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 0 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                        minWidth: 0
                                                    }}
                                                >
                                                    <LocalOffer
                                                        sx={{
                                                            fontSize: '0.9rem',
                                                            color: isLeftMessage ? themeColors.accent : themeColors.primary,
                                                            flexShrink: 0
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{
                                                            fontSize: '0.85rem',
                                                            fontWeight: 600,
                                                            color: isLeftMessage ? 'rgba(0,0,0,0.8)' : 'white',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    >
                                                        Venue Offer
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Chip
                                                size="small"
                                                label={(() => {
                                                    if (message.offer.IsNoMinimumSpend) return 'No Min. Spend';
                                                    if (message.offer.IsPrivateSpace) return 'Private Area';
                                                    if (message.offer.IsSemiPrivateSpace) return 'Semi-Private';
                                                    return 'Standard';
                                                })()}
                                                icon={(() => {
                                                    if (message.offer.IsNoMinimumSpend) return <CheckCircleIcon />;
                                                    if (message.offer.IsPrivateSpace) return <MeetingRoomIcon />;
                                                    if (message.offer.IsSemiPrivateSpace) return <GroupsIcon />;
                                                    return <LocalOffer />;
                                                })()}
                                                sx={{
                                                    bgcolor: (() => {
                                                        if (message.offer.IsNoMinimumSpend)
                                                            return isLeftMessage ? 'rgba(76, 175, 80, 0.1)' : 'rgba(76, 175, 80, 0.2)';
                                                        if (message.offer.IsPrivateSpace)
                                                            return isLeftMessage ? 'rgba(138, 92, 219, 0.1)' : 'rgba(224, 177, 241, 0.15)';
                                                        if (message.offer.IsSemiPrivateSpace)
                                                            return isLeftMessage ? 'rgba(33, 150, 243, 0.1)' : 'rgba(144, 202, 249, 0.15)';
                                                        return isLeftMessage ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.1)';
                                                    })(),
                                                    color: (() => {
                                                        if (message.offer.IsNoMinimumSpend)
                                                            return isLeftMessage ? themeColors.green : 'lightgreen';
                                                        if (message.offer.IsPrivateSpace)
                                                            return isLeftMessage ? themeColors.accent : themeColors.primary;
                                                        if (message.offer.IsSemiPrivateSpace)
                                                            return isLeftMessage ? themeColors.blue : themeColors.lightBlue;
                                                        return isLeftMessage ? 'rgba(0,0,0,0.7)' : 'white';
                                                    })(),
                                                    border: `1px solid ${isLeftMessage ? 'rgba(0,0,0,0.06)' : 'rgba(255,255,255,0.1)'}`,
                                                    '& .MuiChip-icon': {
                                                        fontSize: '0.7rem',
                                                        color: 'inherit'
                                                    },
                                                    height: '24px',
                                                    '& .MuiChip-label': {
                                                        px: 1,
                                                        fontSize: '0.7rem',
                                                        fontWeight: 600
                                                    },
                                                    flexShrink: 0
                                                }}
                                            />
                                        </Box>

                                        {/* Offer Content */}
                                        <Box sx={{
                                            p: 2,
                                            width: '100%',
                                            maxWidth: '100%',
                                            overflow: 'hidden'
                                        }}>
                                            {/* Main price display */}
                                            <Box
                                                sx={{
                                                    mb: 2,
                                                    padding: 2,
                                                    borderRadius: '12px',
                                                    bgcolor: isLeftMessage ? 'rgba(248, 249, 250, 0.5)' : 'rgba(255, 255, 255, 0.05)',
                                                    border: `1px solid ${isLeftMessage ? 'rgba(0,0,0,0.03)' : 'rgba(255, 255, 255, 0.08)'}`,
                                                }}
                                            >
                                                {message.offer.IsNoMinimumSpend ? (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <CheckCircleIcon
                                                            sx={{
                                                                color: isLeftMessage ? themeColors.green : 'lightgreen',
                                                                fontSize: '1.2rem'
                                                            }}
                                                        />
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                color: isLeftMessage ? themeColors.green : 'lightgreen',
                                                                fontWeight: 600,
                                                                fontSize: '1rem'
                                                            }}
                                                        >
                                                            No Minimum Spend Required
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                color: isLeftMessage
                                                                    ? message.offer.EstimatedPrice && message.offer.BasePrice && message.offer.EstimatedPrice < message.offer.BasePrice
                                                                        ? themeColors.green
                                                                        : 'rgba(0,0,0,0.8)'
                                                                    : message.offer.EstimatedPrice && message.offer.BasePrice && message.offer.EstimatedPrice < message.offer.BasePrice
                                                                        ? 'lightgreen'
                                                                        : 'white',
                                                                fontWeight: 700,
                                                                fontSize: '1.2rem',
                                                                mb: 0.5
                                                            }}
                                                        >
                                                            {message.offer.EstimatedPrice
                                                                ? `$${message.offer.EstimatedPrice.toLocaleString()}`
                                                                : message.offer.BasePrice
                                                                    ? `$${message.offer.BasePrice.toLocaleString()}`
                                                                    : 'Custom Pricing'
                                                            }
                                                        </Typography>

                                                        {message.offer.BasePrice && message.offer.EstimatedPrice && message.offer.EstimatedPrice < message.offer.BasePrice && (
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                                                                <Typography
                                                                    component="span"
                                                                    sx={{
                                                                        textDecoration: 'line-through',
                                                                        color: isLeftMessage ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.5)',
                                                                        fontSize: '0.85rem'
                                                                    }}
                                                                >
                                                                    ${message.offer.BasePrice.toLocaleString()}
                                                                </Typography>
                                                                <Chip
                                                                    icon={<PercentIcon />}
                                                                    label={`${calculateDiscountPercentage(message.offer.BasePrice, message.offer.EstimatedPrice)}% off`}
                                                                    size="small"
                                                                    sx={{
                                                                        bgcolor: isLeftMessage ? 'rgba(76, 175, 80, 0.1)' : 'rgba(76, 175, 80, 0.15)',
                                                                        color: isLeftMessage ? themeColors.green : 'lightgreen',
                                                                        height: '20px',
                                                                        fontSize: '0.7rem',
                                                                        border: `1px solid ${isLeftMessage ? 'rgba(76, 175, 80, 0.2)' : 'rgba(76, 175, 80, 0.3)'}`,
                                                                        '& .MuiChip-icon': {
                                                                            color: isLeftMessage ? themeColors.green : 'lightgreen',
                                                                            fontSize: '0.7rem'
                                                                        },
                                                                        '& .MuiChip-label': {
                                                                            px: 0.8,
                                                                            fontSize: '0.7rem',
                                                                            fontWeight: 600
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}

                                                        {(message.offer.DepositAmount || message.offer.DepositPercentage) && (
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    color: isLeftMessage ? 'rgba(0,0,0,0.6)' : themeColors.textMuted,
                                                                    fontSize: '0.75rem',
                                                                    mt: 0.5
                                                                }}
                                                            >
                                                                ${(message.offer.DepositAmount || (message.offer.PriceToCharge * (message.offer.DepositPercentage || 0) / 100)).toFixed(2)} deposit required
                                                                {message.offer.IsDepositRefundable && ' (refundable)'}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>

                                            {/* Date and Time Info */}
                                            <Box sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: 1,
                                                mb: 2,
                                                width: '100%',
                                                overflow: 'hidden'
                                            }}>
                                                <Chip
                                                    icon={<CalendarTodayIcon sx={{ fontSize: '0.85rem' }} />}
                                                    label={dayjs(message.offer.Date).format('MMM D, YYYY')}
                                                    size="small"
                                                    sx={{
                                                        bgcolor: isLeftMessage ? 'rgba(0,0,0,0.03)' : 'rgba(255,255,255,0.03)',
                                                        color: isLeftMessage ? 'rgba(0,0,0,0.8)' : 'white',
                                                        border: `1px solid ${isLeftMessage ? 'rgba(0,0,0,0.06)' : 'rgba(255,255,255,0.08)'}`,
                                                        '& .MuiChip-icon': {
                                                            color: isLeftMessage ? themeColors.accent : themeColors.primary
                                                        },
                                                        '& .MuiChip-label': {
                                                            fontSize: '0.75rem',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        },
                                                        flexShrink: 0
                                                    }}
                                                />
                                                <Chip
                                                    icon={<AccessTimeIcon sx={{ fontSize: '0.85rem' }} />}
                                                    label={`${message.offer.StartTime} - ${message.offer.EndTime}`}
                                                    size="small"
                                                    sx={{
                                                        bgcolor: isLeftMessage ? 'rgba(0,0,0,0.03)' : 'rgba(255,255,255,0.03)',
                                                        color: isLeftMessage ? 'rgba(0,0,0,0.8)' : 'white',
                                                        border: `1px solid ${isLeftMessage ? 'rgba(0,0,0,0.06)' : 'rgba(255,255,255,0.08)'}`,
                                                        '& .MuiChip-icon': {
                                                            color: isLeftMessage ? themeColors.accent : themeColors.primary
                                                        },
                                                        '& .MuiChip-label': {
                                                            fontSize: '0.75rem',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        },
                                                        flexShrink: 0
                                                    }}
                                                />
                                                <Chip
                                                    icon={<TimerIcon sx={{ fontSize: '0.85rem' }} />}
                                                    label="Expires in 48 hours"
                                                    size="small"
                                                    sx={{
                                                        bgcolor: isLeftMessage ? 'rgba(255, 152, 0, 0.08)' : 'rgba(255, 152, 0, 0.15)',
                                                        color: isLeftMessage ? '#f57c00' : '#ffa726',
                                                        border: `1px solid ${isLeftMessage ? 'rgba(255, 152, 0, 0.2)' : 'rgba(255, 152, 0, 0.3)'}`,
                                                        '& .MuiChip-icon': {
                                                            color: isLeftMessage ? '#f57c00' : '#ffa726'
                                                        },
                                                        '& .MuiChip-label': {
                                                            fontSize: '0.75rem',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        },
                                                        flexShrink: 0
                                                    }}
                                                />
                                            </Box>

                                            {/* Cancellation policy */}
                                            {hasFlexibleCancellation(message.offer) && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 0.5,
                                                        mb: 2,
                                                        padding: 1,
                                                        borderRadius: '8px',
                                                        bgcolor: isLeftMessage ? 'rgba(76, 175, 80, 0.05)' : 'rgba(76, 175, 80, 0.1)',
                                                        border: `1px solid ${isLeftMessage ? 'rgba(76, 175, 80, 0.1)' : 'rgba(76, 175, 80, 0.2)'}`,
                                                    }}
                                                >
                                                    <CheckCircleIcon sx={{
                                                        fontSize: '0.9rem',
                                                        color: isLeftMessage ? themeColors.green : 'lightgreen'
                                                    }} />
                                                    <Typography variant="body2" sx={{
                                                        color: isLeftMessage ? themeColors.green : 'lightgreen',
                                                        fontSize: '0.75rem',
                                                        fontWeight: 500
                                                    }}>
                                                        Free cancellation up to 3 days before
                                                    </Typography>
                                                </Box>
                                            )}

                                            {/* Action Button */}
                                            <Button
                                                variant="contained"
                                                endIcon={<ArrowForward />}
                                                onClick={() => window.location.href = `/offer_checkout?offerId=${message.offer?.ID}`}
                                                sx={{
                                                    ...(message.offer.IsNoMinimumSpend
                                                        ? {
                                                            backgroundColor: isLeftMessage ? themeColors.green : 'rgba(76, 175, 80, 0.2)',
                                                            color: isLeftMessage ? 'white' : 'lightgreen',
                                                            '&:hover': {
                                                                backgroundColor: isLeftMessage ? alpha(themeColors.green, 0.9) : 'rgba(76, 175, 80, 0.3)',
                                                            },
                                                        }
                                                        : {
                                                            backgroundColor: isLeftMessage ? themeColors.accent : themeColors.primary,
                                                            color: isLeftMessage ? 'white' : themeColors.darkBg,
                                                            '&:hover': {
                                                                backgroundColor: isLeftMessage ? alpha(themeColors.accent, 0.9) : themeColors.primaryDark,
                                                            },
                                                        }),
                                                    textTransform: 'none',
                                                    fontWeight: 'bold',
                                                    borderRadius: '8px',
                                                    py: 1,
                                                    px: 3,
                                                    width: '100%',
                                                    mt: 1,
                                                    boxShadow: 2,
                                                    transition: 'all 0.2s ease',
                                                    '&:hover': {
                                                        transform: 'translateY(-1px)',
                                                        boxShadow: 3
                                                    }
                                                }}
                                            >
                                                {message.offer.IsNoMinimumSpend ? 'Reserve Now' : 'Book Now'}
                                            </Button>
                                        </Box>

                                        {/* Timestamp at the bottom */}
                                        <Box
                                            sx={{
                                                pb: 1,
                                                px: 2,
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: isLeftMessage ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.6)',
                                                    fontSize: '0.7rem'
                                                }}
                                            >
                                                {new Date(message.CreatedAt).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    timeZone: 'UTC'
                                                })}
                                            </Typography>
                                        </Box>
                                    </Paper>
                                )}
                            </Box>
                        </Box>
                    );
                })}
                {/* This element is used for scrolling to the bottom */}
                <div ref={messagesEndRef} style={{ paddingBottom: '8px' }} />
            </Box>
        </Box>
    );
}
