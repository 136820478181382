import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Rating } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Venue, VenueBookedTimeSlot, DayOfWeek } from '../../../shared/models';
import Link from 'next/link';
import { getAttributeIcons } from '../lib/firebase/metadata';
import Attributes from './attributes';
import ImageCarouselSwiper from './image_carousel_swiper';
import LinkWithLoading from './link_with_loading';

interface VenueFloatingCardProps {
  venue: Venue;
  venueBookedTimeSlots: Record<string, VenueBookedTimeSlot[]>;
  onClose: () => void;
  isDesktop: boolean;
  venuePrice: number;
  date?: string;
  time?: string;
  size?: number;
}

const VenueFloatingCard: React.FC<VenueFloatingCardProps> = ({
  venue,
  venueBookedTimeSlots,
  onClose,
  isDesktop,
  venuePrice,
  date,
  time,
  size
}) => {
  const [attributeIcons, setAttributeIcons] = useState<Record<string, string>>({});
  const [nextAvailabilities, setNextAvailabilities] = useState<string[]>([]);
  const [firstAvailabilityDate, setFirstAvailabilityDate] = useState<Date | null>(null);

  // Check if venue has no minimum spend option
  const hasNoMinimumSpendOption = useMemo(() => {
    if (!size) return false;
    // Find suitable sections that can accommodate the party size
    const suitableSections = Object.keys(venue.Sections)
      .filter(sectionId => venue.Sections[sectionId].Capacity >= size);
    // Check if any of the suitable sections has a no minimum spend option
    const hasNoMinSpend = suitableSections.some(sectionId => {
      const pricingRate = venue.PricingRatesPerSection[sectionId];
      const result = pricingRate?.IsNoMinimumSpendOption === true;
      return result;
    });

    return hasNoMinSpend;
  }, [venue, size]);

  useEffect(() => {
    const fetchAttributeIcons = async () => {
      const icons = await getAttributeIcons();
      setAttributeIcons(icons);
    };
    fetchAttributeIcons();
  }, []);

  useEffect(() => {
    setNextAvailabilities(getNextAvailabilities(venue));
    setFirstAvailabilityDate(getFirstAvailabilityDate(venue));
  }, [venue, venueBookedTimeSlots]);

  const getNextAvailabilities = (venue: Venue): string[] => {
    const today = new Date();
    const nextDays: string[] = [];

    for (let i = 0; i < 7 && nextDays.length < 4; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      const dayOfWeek = nextDate.toLocaleString('en-US', { weekday: 'long' }) as DayOfWeek;
      const dateString = nextDate.toDateString();
      const formattedDate = `${nextDate.getMonth() + 1}/${nextDate.getDate()}`;

      if (venue.Availability[dayOfWeek] && venue.Availability[dayOfWeek].IsOpen && venueBookedTimeSlots && venueBookedTimeSlots[dateString]) {
        if (!venueBookedTimeSlots[dateString] || venueBookedTimeSlots[dateString].length === 0) {
          nextDays.push(formattedDate);
        }
      }
    }

    return nextDays;
  };

  const getFirstAvailabilityDate = (venue: Venue): Date => {
    return Object.values(venue.Availability).some(day => day.IsOpen)
      ? new Date()
      : new Date();
  };

  const cardClasses = isDesktop
    ? 'absolute top-4 left-4 z-10 w-80 shadow-xl transition-transform duration-300'
    : 'fixed bottom-0 left-0 right-0 z-10 shadow-xl';

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const venueUrlParams = new URLSearchParams();
  if (date) venueUrlParams.append('date', date);
  if (time) venueUrlParams.append('time', time);
  if (size) venueUrlParams.append('size', size.toString());

  const queryString = venueUrlParams.toString();
  const venueUrl = `/venue/${venue.ID}${queryString ? `?${queryString}` : ''}`;

  // Prepare image data for the carousel
  const imageData = useMemo(() => {
    return venue.PhotoUrls
      ? venue.PhotoUrls.map((url, index) => ({
        src: url,
        key: `${venue.ID}-${url}-${index}`,
      }))
      : [];
  }, [venue.PhotoUrls, venue.ID]);

  return (
    <LinkWithLoading
      href={venueUrl}
      passHref
      target="_blank"
      rel="noopener noreferrer"
    >
      <Card
        className={cardClasses}
        sx={{
          backgroundColor: 'rgba(60, 60, 84, 0.9)',
          color: 'white',
          cursor: 'pointer',
          borderRadius: '16px',
          overflow: 'hidden',
          ':hover': {
            transform: 'scale(1.02)'
          }
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button - Absolute positioned on top right */}
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            handleClose(e);
          }}
          size="small"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 20,
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: 32,
            height: 32,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)'
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        {/* Image Carousel */}
        <div className="relative w-full pb-[80%] overflow-hidden">
          <div className="absolute inset-0">
            <ImageCarouselSwiper images={imageData} />
          </div>
        </div>

        <CardContent sx={{ p: 2, pb: '16px !important' }}>
          {/* Header with Title and Rating */}
          <div className="flex justify-between items-center mb-1">
            <h3 className="text-[18px] leading-[1.1] truncate flex-1 pr-2 font-medium">{venue.Name}</h3>
            <span className="flex items-center text-sm font-medium">
              <span className="text-gray-300 mr-1">★</span>
              <span className="font-sans text-gray-300">{venue.Rating || 5}</span>
            </span>
          </div>

          {/* Attributes Section */}
          <div className="mt-1.5 mb-1.5">
            <Attributes
              attributes={venue.Attributes || {}}
              attributeIcons={attributeIcons}
              maxAttributes={2}
              muted={false}
            />
          </div>

          {/* No Minimum Spend Badge */}
          {hasNoMinimumSpendOption && (
            <div className="mb-2">
              <div className="inline-flex items-center bg-gradient-to-r from-purple-500/30 to-purple-400/30 px-2.5 py-1 rounded-full text-white border border-purple-300/40 shadow-sm">
                <svg className="w-3 h-3 mr-1 text-purple-200" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                </svg>
                <span className="text-xs font-semibold">No minimum spend</span>
              </div>
            </div>
          )}

          {/* Availability Info */}
          <div className="text-gray-400 text-[13px] leading-tight font-sans mb-1.5">
            <span>Available starting </span>
            {nextAvailabilities.map((date, index) => (
              <React.Fragment key={date}>
                {index > 0 && <span>, </span>}
                <span className="font-semibold text-white">{date}</span>
              </React.Fragment>
            ))}
          </div>

          {/* Price Section */}
          <div className="price-section font-sans leading-tight mt-2">
            {hasNoMinimumSpendOption ? (
              <div className="flex items-baseline">
                <span className="text-emerald-400 text-[15px] font-semibold mr-1">FREE</span>
                <span className="text-gray-200 text-[14px]">per person</span>
              </div>
            ) : (
              <div className="flex items-baseline">
                <span className="text-[15px] font-semibold mr-1">${Math.round(venuePrice).toLocaleString()}</span>
                <span className="text-gray-200 text-[14px]">per person</span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </LinkWithLoading>
  );
};

export default VenueFloatingCard;