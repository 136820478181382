'use client';

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { APIProvider, Map, AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { Venue, VenueBookedTimeSlot } from '../../../shared/models';
import VenueFloatingCard from './VenueFloatingCard';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

interface ClusterMarker {
  position: { lat: number; lng: number };
  count: number;
  venueIds: string[];
  lowestPrice?: number;
  hasNoMinSpend: boolean;
}

interface MapProps {
  venues: Venue[];
  otherVenues?: Venue[];
  venueBookedTimeSlots: Record<string, Record<string, VenueBookedTimeSlot[]>>;
  center?: [number, number];
  zoom?: number;
  selectedVenue?: Venue | null;
  onMarkerClick?: (venue: Venue) => void;
  viewOnly?: boolean;
  hoveredVenueId: string | null;
  venuePrices?: Record<string, number>;
  date?: string;
  time?: string;
  size?: number;
  onBoundsChange?: (visibleVenueIds: string[]) => void;
  isMapFiltered?: boolean;
}

const MapMarkers: React.FC<MapProps> = ({
  venues,
  otherVenues = [],
  venueBookedTimeSlots,
  selectedVenue: initialSelectedVenue,
  viewOnly = false,
  hoveredVenueId,
  venuePrices,
  onMarkerClick,
  date,
  time,
  size,
  onBoundsChange,
  isMapFiltered = false,
}) => {
  const map = useMap();
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(initialSelectedVenue || null);
  const [currentZoom, setCurrentZoom] = useState<number | undefined>(map?.getZoom());
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | undefined>(
    map?.getBounds()
  );
  // Add local hover state
  const [mouseHoveredId, setMouseHoveredId] = useState<string | null>(null);

  // Track previous map filtered state to detect changes
  const prevIsMapFiltered = useRef(isMapFiltered);
  // Add ref to track the last set of visible venue IDs to prevent unnecessary updates
  const lastVisibleIds = useRef<string[]>([]);

  // Adjust map when map filtering is turned off
  useEffect(() => {
    if (!map || venues.length === 0) return;

    // Only adjust when transitioning from filtered to unfiltered
    if (prevIsMapFiltered.current && !isMapFiltered) {
      // If map filtering was just disabled, fit the map to show all venues
      const bounds = new google.maps.LatLngBounds();
      venues.forEach(venue => {
        if (venue.Position) {
          bounds.extend(new google.maps.LatLng(venue.Position.lat, venue.Position.lng));
        }
      });

      // Add padding to the bounds
      map.fitBounds(bounds, { top: 50, right: 50, bottom: 50, left: 50 });

      // If too zoomed out, limit the zoom level
      const timeoutId = setTimeout(() => {
        if (map.getZoom() < 10) {
          map.setZoom(11);
        }
      }, 100);

      return () => clearTimeout(timeoutId);
    }

    // Update ref for next comparison
    prevIsMapFiltered.current = isMapFiltered;
  }, [isMapFiltered, venues, map]);

  // Function to check if the venue has a no minimum spend option for the given party size
  const hasNoMinimumSpendOption = (venue: Venue, partySize?: number): boolean => {
    if (!partySize) return false;


    // Find suitable sections that can accommodate the party size
    const suitableSections = Object.keys(venue.Sections)
      .filter(sectionId => venue.Sections[sectionId].Capacity >= partySize);
    // Check if any of the suitable sections has a no minimum spend option
    const hasNoMinSpend = suitableSections.some(sectionId => {
      const pricingRate = venue.PricingRatesPerSection[sectionId];
      const result = pricingRate?.IsNoMinimumSpendOption === true;
      return result;
    });

    // Return true if either condition is met
    const finalResult = hasNoMinSpend;
    return finalResult;
  };

  const handleMarkerClick = (venue: Venue) => {
    if (viewOnly) return;
    setSelectedVenue(venue);
    if (onMarkerClick) {
      onMarkerClick(venue);
    }

    if (map) {
      // Pan slightly to the left of the marker by subtracting a small offset from longitude
      map.panTo({ lat: venue.Position.lat, lng: venue.Position.lng - 0.006 });
      map.setZoom(15);
    }
  };

  // Listen for zoom and bounds changes
  useEffect(() => {
    if (!map) return;

    const zoomListener = map.addListener('zoom_changed', () => {
      setCurrentZoom(map.getZoom());
    });

    const boundsListener = map.addListener('bounds_changed', () => {
      setMapBounds(map.getBounds());
    });

    return () => {
      google.maps.event.removeListener(zoomListener);
      google.maps.event.removeListener(boundsListener);
    };
  }, [map]);

  // Update visible venues when map bounds change
  useEffect(() => {
    if (!mapBounds || !onBoundsChange) return;

    // Use a ref to track the last visible venue ids to reduce unnecessary callbacks
    const currentVisibleVenueIds = [...venues, ...otherVenues]
      .filter(venue => {
        if (!venue.Position) return false;
        const pos = new google.maps.LatLng(venue.Position.lat, venue.Position.lng);
        return mapBounds.contains(pos);
      })
      .map(venue => venue.ID);

    // Compare with stored value to avoid unnecessary updates
    if (JSON.stringify(currentVisibleVenueIds) !== JSON.stringify(lastVisibleIds.current)) {
      // Notify parent component about visible venues
      onBoundsChange(currentVisibleVenueIds);
      lastVisibleIds.current = currentVisibleVenueIds;
    }
  }, [mapBounds, venues, otherVenues, onBoundsChange]);

  // Handle highlighting markers on hover without moving the map
  useEffect(() => {
    // We no longer pan or zoom the map on hover
    // The hover effect is handled through the marker styling
    // This creates a more stable user experience similar to Airbnb
  }, [hoveredVenueId]);

  // Group venues by their exact location
  const getLocationKey = (position: { lat: number; lng: number }) =>
    `${position.lat.toFixed(5)},${position.lng.toFixed(5)}`;

  // Cluster venues based on zoom level and proximity
  const { visibleVenues, visibleOtherVenues } = useMemo(() => {
    if (!currentZoom || !mapBounds) {
      return {
        visibleVenues: venues,
        visibleOtherVenues: []  // Don't show outside filter venues by default
      };
    }

    // Always show selected venue
    const selectedVenueId = selectedVenue?.ID;

    // Always show hovered venue
    const alwaysShowVenueIds = new Set<string>();
    if (selectedVenueId) alwaysShowVenueIds.add(selectedVenueId);
    if (hoveredVenueId) alwaysShowVenueIds.add(hoveredVenueId);

    // Determine which venues are in view
    const inViewVenues = venues.filter(venue => {
      if (!mapBounds) return true;
      // Use position literal instead of LatLng constructor
      return mapBounds.contains({ lat: venue.Position.lat, lng: venue.Position.lng });
    });

    // Determine which outside filter venues are in view
    const inViewOtherVenues = otherVenues.filter(venue => {
      if (!mapBounds) return true;
      // Use position literal instead of LatLng constructor
      return mapBounds.contains({ lat: venue.Position.lat, lng: venue.Position.lng });
    });

    // Group venues by their location (similar to Airbnb's approach)
    const locationGroups: Record<string, Venue[]> = {};
    const otherLocationGroups: Record<string, Venue[]> = {};

    // Function to determine grid cell size based on zoom level
    const getGridKey = (position: { lat: number; lng: number }) => {
      // Adjust the precision based on zoom level
      // Lower zoom = less precision (bigger cells)
      let precision = 4; // default precision

      if (currentZoom < 12) {
        precision = 3;
      } else if (currentZoom < 14) {
        precision = 4;
      } else if (currentZoom < 16) {
        precision = 5;
      } else {
        precision = 6; // Very high precision at high zoom levels
      }

      return `${position.lat.toFixed(precision)},${position.lng.toFixed(precision)}`;
    };

    // Group main venues by location
    inViewVenues.forEach(venue => {
      const key = getGridKey(venue.Position);
      if (!locationGroups[key]) {
        locationGroups[key] = [];
      }
      locationGroups[key].push(venue);
    });

    // Group other venues by location
    inViewOtherVenues.forEach(venue => {
      const key = getGridKey(venue.Position);
      if (!otherLocationGroups[key]) {
        otherLocationGroups[key] = [];
      }
      otherLocationGroups[key].push(venue);
    });

    // Determine how many venues to show per location group based on zoom level
    const getMaxVenuesPerCell = () => {
      if (currentZoom < 11) return 1;
      if (currentZoom < 13) return 2;
      if (currentZoom < 15) return 3;
      return 10; // Show most venues at high zoom levels
    };

    // For "other" venues, show even fewer
    const getMaxOtherVenuesPerCell = () => {
      // Always show at least one outside filter venue even at low zoom
      // Previously this returned 0 at low zoom levels, hiding them completely
      if (currentZoom < 13) return 1;
      if (currentZoom < 15) return 1;
      return 2; // Show limited outside filter venues
    };

    const maxVenuesPerCell = getMaxVenuesPerCell();
    const maxOtherVenuesPerCell = getMaxOtherVenuesPerCell();

    // Select limited venues from each cell
    const finalVisibleVenues: Venue[] = [];
    const finalVisibleOtherVenues: Venue[] = [];

    // Process main venues
    Object.values(locationGroups).forEach(group => {
      // Always include venues that should always be shown
      const alwaysShowVenues = group.filter(venue => alwaysShowVenueIds.has(venue.ID));
      const remainingVenues = group.filter(venue => !alwaysShowVenueIds.has(venue.ID));

      // Sort remaining venues by price (lower price first) if prices are available
      if (venuePrices) {
        remainingVenues.sort((a, b) => {
          const priceA = venuePrices[a.ID] || Infinity;
          const priceB = venuePrices[b.ID] || Infinity;
          return priceA - priceB;
        });
      }

      // Take venues up to the limit
      const venuesToShow = [
        ...alwaysShowVenues,
        ...remainingVenues.slice(0, Math.max(0, maxVenuesPerCell - alwaysShowVenues.length))
      ];

      finalVisibleVenues.push(...venuesToShow);
    });

    // Process other venues
    Object.values(otherLocationGroups).forEach(group => {
      // Always include venues that should always be shown
      const alwaysShowVenues = group.filter(venue => alwaysShowVenueIds.has(venue.ID));
      const remainingVenues = group.filter(venue => !alwaysShowVenueIds.has(venue.ID));

      // Sort by price if available
      if (venuePrices) {
        remainingVenues.sort((a, b) => {
          const priceA = venuePrices[a.ID] || Infinity;
          const priceB = venuePrices[b.ID] || Infinity;
          return priceA - priceB;
        });
      }

      // Take venues up to the limit
      const venuesToShow = [
        ...alwaysShowVenues,
        ...remainingVenues.slice(0, Math.max(0, maxOtherVenuesPerCell - alwaysShowVenues.length))
      ];

      finalVisibleOtherVenues.push(...venuesToShow);
    });

    return {
      visibleVenues: finalVisibleVenues,
      visibleOtherVenues: finalVisibleOtherVenues
    };
  }, [venues, otherVenues, currentZoom, mapBounds, selectedVenue, hoveredVenueId, venuePrices, size, hasNoMinimumSpendOption]);

  const calculateOffsets = (venues: Venue[]) => {
    const locationGroups: Record<string, Venue[]> = {};

    venues.forEach(venue => {
      const key = getLocationKey(venue.Position);
      if (!locationGroups[key]) {
        locationGroups[key] = [];
      }
      locationGroups[key].push(venue);
    });

    const offsets: Record<string, { lat: number; lng: number }> = {};

    Object.entries(locationGroups).forEach(([key, groupVenues]) => {
      if (groupVenues.length > 1) {
        // Only calculate offsets for locations with multiple venues
        groupVenues.forEach((venue, index) => {
          const gridSize = 0.0008;
          const x = (index % 3) * gridSize;
          const y = Math.floor(index / 3) * gridSize;
          const centerOffsetX = -gridSize;
          const centerOffsetY = -gridSize;

          offsets[venue.ID] = {
            lat: x + centerOffsetX,
            lng: y + centerOffsetY
          };
        });
      }
    });

    return offsets;
  };

  const venueOffsets = calculateOffsets([...visibleVenues, ...visibleOtherVenues]);

  const handleClusterClick = (cluster: ClusterMarker) => {
    if (!map) return;

    // Zoom in to the cluster
    map.setZoom((currentZoom || 13) + 2);
    map.panTo(cluster.position);
  };

  // Add this function inside the MapMarkers component to determine if we're in a clustered view
  const isClusteredView = () => {
    // Consider the view clustered when:
    // 1. Zoom level is below a certain threshold, or
    // 2. There are many venues visible
    return (currentZoom && currentZoom < 14) || visibleVenues.length > 15;
  };

  return (
    <>
      <style jsx global>{`
        .hidden {
          display: none;
        }
        button:hover .hidden {
          display: block;
        }
      `}</style>
      {/* Render secondary venues (outside filters) first so they appear under main venues */}
      {visibleOtherVenues.map((venue, index) => (
        <AdvancedMarker
          key={`other-${venue.ID}`}
          clickable={true}
          onClick={() => handleMarkerClick(venue)}
          position={{
            lat: venue.Position.lat + (venueOffsets[venue.ID]?.lat || 0),
            lng: venue.Position.lng + (venueOffsets[venue.ID]?.lng || 0),
          }}
        >
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{
              scale: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 1.1 : 0.8,
              y: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? -5 : 0,
              boxShadow: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? '0px 0px 15px rgba(224, 177, 241, 0.6)' : 'none',
            }}
          >
            <Button
              onClick={() => handleMarkerClick(venue)}
              onMouseEnter={() => setMouseHoveredId(venue.ID)}
              onMouseLeave={() => setMouseHoveredId(null)}
              sx={{
                minWidth: 'auto',
                padding: (currentZoom && currentZoom < 13) ? '2px' : '4px 8px',
                borderRadius: (currentZoom && currentZoom < 13) ? '50%' : '20px',
                width: (currentZoom && currentZoom < 13) ? '8px' : 'auto',
                height: (currentZoom && currentZoom < 13) ? '8px' : 'auto',
                backgroundColor: (currentZoom && currentZoom < 13) ? '#222222' : '#353644',
                border: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID
                  ? '2px solid rgba(224, 177, 241, 0.8)'
                  : (currentZoom && currentZoom < 13) ? 'none' : '1px solid rgba(0, 0, 0, 0.08)',
                color: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? '#ffffff' : '#cccccc',
                boxShadow: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID
                  ? '0px 4px 8px rgba(0, 0, 0, 0.2)'
                  : '0px 1px 2px rgba(0, 0, 0, 0.08)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                lineHeight: 1,
                opacity: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 0.95 : (currentZoom && currentZoom < 13) ? 0.6 : 0.75,
                zIndex: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 5 : 'auto',
                '&:hover': {
                  backgroundColor: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID
                    ? '#454755'
                    : (currentZoom && currentZoom < 13) ? '#333333' : '#353644',
                  opacity: (currentZoom && currentZoom < 13) ? 0.8 : 0.9,
                },
              }}
            >
              {venuePrices && venuePrices[venue.ID] && !(currentZoom && currentZoom < 13) ? (
                <span style={{ fontSize: '12px', fontWeight: 500, fontFamily: 'Sans' }}>
                  ${Math.round(venuePrices[venue.ID])}
                </span>
              ) : (
                (currentZoom && currentZoom < 13) ? null : (
                  <span style={{ fontSize: '11px', fontWeight: 500, fontFamily: 'Sans' }}>
                    $
                  </span>
                )
              )}
            </Button>
          </motion.div>
        </AdvancedMarker>
      ))}

      {/* Render main venues on top */}
      {visibleVenues.map((venue, index) => (
        <AdvancedMarker
          clickable={true}
          onClick={() => handleMarkerClick(venue)}
          key={venue.ID}
          position={{
            lat: venue.Position.lat + (venueOffsets[venue.ID]?.lat || 0),
            lng: venue.Position.lng + (venueOffsets[venue.ID]?.lng || 0),
          }}
          zIndex={hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 1000 : 100} /* Ensure hovered venues always appear on top */
        >
          <motion.div
            initial={{ scale: 1 }}
            animate={{
              scale: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 1.2 : 1,
              boxShadow: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? '0px 0px 20px rgba(224, 177, 241, 0.8)' : 'none',
              y: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? -8 : 0,
              zIndex: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 10 : 0,
            }}
            transition={{ duration: 0.3 }}
          >
            {venuePrices && venuePrices[venue.ID] ? (
              <div className={`relative ${hoveredVenueId === venue.ID || mouseHoveredId === venue.ID ? 'z-50' : ''}`}>
                <Button
                  onClick={() => handleMarkerClick(venue)}
                  onMouseEnter={() => setMouseHoveredId(venue.ID)}
                  onMouseLeave={() => setMouseHoveredId(null)}
                  sx={{
                    minWidth: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? '28px' : '36px',
                    padding: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? '3px 6px' : '4px 8px',
                    borderRadius: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? '20px' : '28px',
                    backgroundColor: hasNoMinimumSpendOption(venue, size) ? '#00A699' : '#E0B1F1',
                    color: hasNoMinimumSpendOption(venue, size) ? 'white' : 'black',
                    boxShadow: hoveredVenueId === venue.ID || mouseHoveredId === venue.ID
                      ? '0px 8px 16px rgba(0, 0, 0, 0.3)'
                      : '0px 2px 4px rgba(0, 0, 0, 0.18)',
                    transition: 'transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    lineHeight: 1,
                    transform: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? 'scale(0.85)' : 'scale(1.1)',
                    '&:hover': {
                      backgroundColor: hasNoMinimumSpendOption(venue, size) ? '#008F89' : '#D8A8E8',
                    },
                    '&:active': {
                      transform: 'scale(0.96)',
                      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.18)',
                    },
                    ...(selectedVenue && selectedVenue.ID === venue.ID && {
                      backgroundColor: hasNoMinimumSpendOption(venue, size) ? '#008F89' : '#222222',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: hasNoMinimumSpendOption(venue, size) ? '#008F89' : '#222222',
                      },
                    }),
                    ...(hasNoMinimumSpendOption(venue, size) && {
                      border: '2px solid white',
                      padding: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? '3px 6px' : '6px 10px',
                    }),
                    // Add specific styles for very zoomed out view
                    ...(currentZoom && currentZoom < 11 && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID && {
                      minWidth: '12px',
                      width: '12px',
                      height: '12px',
                      padding: '0',
                      borderRadius: '50%',
                      border: hasNoMinimumSpendOption(venue, size) ? '1px solid white' : 'none',
                      transform: 'scale(0.9)',
                    }),
                  }}
                >
                  {hasNoMinimumSpendOption(venue, size) ? (
                    <>
                      {(currentZoom && currentZoom < 11 && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID) ? null : (
                        <>
                          <span style={{
                            fontSize: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? '11px' : '14px',
                            fontWeight: 700,
                            fontFamily: 'Sans',
                            letterSpacing: '0.2px'
                          }}>
                            FREE
                          </span>
                          <span style={{
                            fontSize: '8px',
                            fontWeight: 500,
                            fontFamily: 'Sans',
                            marginTop: '2px',
                            display: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? 'none' : 'block'
                          }}>
                            no min
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {(currentZoom && currentZoom < 11 && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID) ? null : (
                        <>
                          <span style={{
                            fontSize: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? '12px' : '15px',
                            fontWeight: 600,
                            fontFamily: 'Sans'
                          }}>
                            ${Math.round(venuePrices[venue.ID])}
                          </span>
                          <span style={{
                            fontSize: '8px',
                            fontWeight: 400,
                            fontFamily: 'Sans',
                            marginTop: '1px',
                            display: isClusteredView() && hoveredVenueId !== venue.ID && mouseHoveredId !== venue.ID ? 'none' : 'block'
                          }}>
                            per person
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Button>
              </div>
            ) : (
              <motion.div
                style={{
                  width: 80,
                  height: 60,
                  backgroundImage: `url(${selectedVenue && selectedVenue.ID === venue.ID ? '/img/selected-cocktail-glass-svg.svg' : '/img/cocktail-glass-svg.svg'})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'pointer',
                }}
              />
            )}
          </motion.div>
        </AdvancedMarker>
      ))}
    </>
  );
};

const BetterMap: React.FC<MapProps> = ({
  venues,
  otherVenues = [],
  venueBookedTimeSlots,
  center,
  zoom,
  selectedVenue: initialSelectedVenue,
  viewOnly = false,
  hoveredVenueId,
  venuePrices,
  onMarkerClick,
  date,
  time,
  size,
  onBoundsChange,
  isMapFiltered = false,
}) => {
  const mainVenues = venues.length > 0 ? venues : otherVenues;
  const secondaryVenues = venues.length > 0 ? otherVenues : [];

  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(initialSelectedVenue || null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Calculate initial zoom based on venue count
  const calculatedInitialZoom = useMemo(() => {
    const totalVenues = venues.length + otherVenues.length;

    // Adjust zoom based on venue count to avoid cluttering
    if (totalVenues > 40) return 11;
    if (totalVenues > 20) return 12;
    if (totalVenues > 10) return 13;
    return zoom || 14; // Default to provided zoom or 14
  }, [venues.length, otherVenues.length, zoom]);

  // Calculate map bounds to fit all venues
  const initialBounds = useMemo(() => {
    if ((venues.length + otherVenues.length) <= 1 || center) {
      // If there's only one venue or center is specified, don't calculate bounds
      return undefined;
    }

    const allVenues = [...venues, ...otherVenues];
    if (allVenues.length === 0) return undefined;

    // Use LatLngBoundsLiteral instead of LatLngBounds constructor directly
    // This avoids the "google.maps.LatLngBounds is not a constructor" error
    // because we're not trying to use the Google Maps API before it's loaded
    let north = -90, south = 90, east = -180, west = 180;

    allVenues.forEach(venue => {
      if (venue.Position) {
        north = Math.max(north, venue.Position.lat);
        south = Math.min(south, venue.Position.lat);
        east = Math.max(east, venue.Position.lng);
        west = Math.min(west, venue.Position.lng);
      }
    });

    // Add a small padding to the bounds
    const padding = 0.03;
    return {
      north: north + padding,
      south: south - padding,
      east: east + padding,
      west: west - padding
    };
  }, [venues, otherVenues, center]);

  // Reference to the map instance
  const mapRef = useRef<google.maps.Map | null>(null);
  // Prevent the centering effect from running on initial render
  const isInitialRender = useRef(true);
  // Track previous venue IDs to avoid unnecessary recentering
  const prevVenueIds = useRef<string[]>([]);

  // Get map instance using useMap hook inside a component that has access to the Map context
  const MapInstanceGetter = () => {
    const map = useMap();

    useEffect(() => {
      if (map) {
        mapRef.current = map;
      }
    }, [map]);

    return null;
  };

  // Center map to show all venues whenever venues change
  useEffect(() => {
    const map = mapRef.current;
    if (!map || (venues.length === 0 && otherVenues.length === 0)) return;

    // Skip if center prop is provided (manual center takes precedence)
    if (center) return;

    // Get current venue IDs
    const allVenues = [...venues, ...otherVenues];
    const currentVenueIds = allVenues.map(venue => venue.ID).sort();

    // Don't recenter if there are no venues
    if (allVenues.length === 0) return;

    // Skip if venues haven't actually changed (prevents unnecessary recentering)
    const prevIds = prevVenueIds.current;
    const venuesUnchanged =
      prevIds.length === currentVenueIds.length &&
      prevIds.every((id, index) => id === currentVenueIds[index]);

    if (venuesUnchanged && !isInitialRender.current) return;

    // Update ref with current venue IDs for next comparison
    prevVenueIds.current = currentVenueIds;

    // Only create bounds if Google Maps is available
    try {
      // Create bounds to fit all venues
      const bounds = new google.maps.LatLngBounds();

      // Add all venue positions to bounds
      allVenues.forEach(venue => {
        if (venue.Position) {
          bounds.extend({ lat: venue.Position.lat, lng: venue.Position.lng });
        }
      });

      // Prevent the effect from running on initial render if initialBounds is already set
      // This avoids fighting with the defaultBounds prop
      if (isInitialRender.current && initialBounds) {
        isInitialRender.current = false;
        return;
      }

      // Add padding to the bounds
      map.fitBounds(bounds, { top: 70, right: 70, bottom: 70, left: 70 });

      // If too zoomed out or in, adjust zoom level for better visibility
      const timeoutId = setTimeout(() => {
        const currentZoom = map.getZoom();
        if (!currentZoom) return;

        if (currentZoom < 10) {
          map.setZoom(11);
        } else if (currentZoom > 18) {
          map.setZoom(17);
        }
      }, 100);

      return () => clearTimeout(timeoutId);
    } catch (error) {
      console.warn('Error while setting map bounds:', error);
      // If we can't set bounds, at least update the initial render flag
      isInitialRender.current = false;
    }
  }, [venues, otherVenues, center, initialBounds]);

  const handleMarkerClick = (venue: Venue) => {
    setSelectedVenue(venue);
    if (onMarkerClick) {
      onMarkerClick(venue);
    }
  };

  return (
    <div className="relative w-full h-full rounded-2xl overflow-hidden">
      <APIProvider apiKey={'AIzaSyB6LI2g_JdVLPiw8ormH-Jgfx7g34Gszzk'}>
        <Map
          id={'main-map'}
          className="h-full w-full"
          defaultCenter={center ? { lat: center[0], lng: center[1] } : { lat: 40.7271195, lng: -73.9914856 }}
          defaultZoom={calculatedInitialZoom}
          defaultBounds={initialBounds}
          gestureHandling={'greedy'}
          mapId='60c72dfa6858ef82'
          disableDefaultUI
        >
          <MapInstanceGetter />
          <MapMarkers
            venues={venues}
            otherVenues={otherVenues}
            venueBookedTimeSlots={venueBookedTimeSlots}
            selectedVenue={selectedVenue}
            viewOnly={viewOnly}
            hoveredVenueId={hoveredVenueId}
            venuePrices={venuePrices}
            onMarkerClick={handleMarkerClick}
            date={date}
            time={time}
            size={size}
            onBoundsChange={onBoundsChange}
            isMapFiltered={isMapFiltered}
          />
        </Map>
        {selectedVenue && (
          <VenueFloatingCard
            venue={selectedVenue}
            venueBookedTimeSlots={venueBookedTimeSlots && venueBookedTimeSlots[selectedVenue.ID]}
            onClose={() => setSelectedVenue(null)}
            isDesktop={isDesktop}
            venuePrice={venuePrices && venuePrices[selectedVenue.ID]}
            date={date}
            time={time}
            size={size}
          />
        )}
      </APIProvider>
    </div>
  );
};

// Export a dynamic version of BetterMap with SSR disabled
export default React.memo(BetterMap);

// This component should be dynamically imported with SSR disabled when used in pages
// Example usage:
// const DynamicBetterMap = dynamic(() => import('./better_map'), { ssr: false }); 