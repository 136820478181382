'use client';

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { SvgIconProps } from '@mui/material';
// Import common icons directly instead of using dynamic imports
import { COMMON_ICONS, IconNames } from '../lib/icon-utils';

interface AttributeProps {
  attributes: Record<string, boolean>;
  attributeIcons: Record<string, string>;
  maxAttributes?: number;
  muted?: boolean;
}

// This is a static cache for loaded icons
const ICON_CACHE: Record<string, React.ComponentType<SvgIconProps>> = {};

const Attributes: React.FC<AttributeProps> = ({
  attributes,
  attributeIcons,
  maxAttributes = 2,
  muted = false,
}) => {
  const [loadedIcons, setLoadedIcons] = useState<Record<string, React.ComponentType<SvgIconProps>>>({});

  const hiddenAttributes = ['bar', 'restaurant', 'fancy', 'casual'];
  const filteredAttributes = Object.keys(attributes).filter(attr => !hiddenAttributes.includes(attr.toLowerCase()));
  const sortedAttributes = filteredAttributes.sort();

  // Pre-load attribute icons that will be used in this render
  const visibleAttributes = useMemo(() =>
    sortedAttributes.slice(0, maxAttributes),
    [sortedAttributes, maxAttributes]
  );

  // Load icons statically from the COMMON_ICONS map
  useEffect(() => {
    const loadIconsAsync = async () => {
      const newlyLoadedIcons: Record<string, React.ComponentType<SvgIconProps>> = {};

      for (const attribute of visibleAttributes) {
        const iconName = attributeIcons[attribute.toLowerCase()];

        // Skip if icon name not found or already loaded
        if (!iconName || loadedIcons[iconName]) continue;

        // Check if icon is already in cache
        if (ICON_CACHE[iconName]) {
          newlyLoadedIcons[iconName] = ICON_CACHE[iconName];
          continue;
        }

        // Check if icon is in COMMON_ICONS
        if (iconName in COMMON_ICONS) {
          try {
            // Load icon from common icons
            const module = await COMMON_ICONS[iconName as IconNames]();
            // Fix: Use type assertion to handle the icon component correctly
            const IconComponent = module as unknown as React.ComponentType<SvgIconProps>;
            ICON_CACHE[iconName] = IconComponent; // Cache for future use
            newlyLoadedIcons[iconName] = IconComponent;
          } catch (error) {
            console.warn(`Failed to load icon: ${iconName}`, error);
          }
        }
      }

      // Only update state if we have new icons
      if (Object.keys(newlyLoadedIcons).length > 0) {
        setLoadedIcons(prev => ({
          ...prev,
          ...newlyLoadedIcons
        }));
      }
    };

    loadIconsAsync();
  }, [visibleAttributes, attributeIcons, loadedIcons]);

  const getIconComponent = (iconName: string) => {
    if (!iconName) return null;

    // Use loaded component if available
    const IconComponent = loadedIcons[iconName];
    if (IconComponent) {
      return (
        <div style={{ marginRight: '2px', fontSize: '0.9rem', display: 'inline-flex' }}>
          <IconComponent />
        </div>
      );
    }

    // Return null if the icon isn't loaded yet
    return null;
  };

  return (
    <Grid container spacing={0.5} alignItems="center" sx={{ height: 'auto' }}>
      {sortedAttributes.slice(0, maxAttributes).map((attribute, index) => {
        const iconName = attributeIcons[attribute.toLowerCase()];
        return (
          <Grid item key={index}>
            <Box
              component='kbd'
              sx={{
                backgroundColor: muted ? 'rgba(255,255,255,0.1)' : 'rgba(224,177,241,0.25)',
                color: muted ? '#FFFFFF' : '#F0C5FF',
                fontFamily: 'Sans',
                fontSize: '0.8rem',
                padding: '1px 6px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                margin: '2px',
                height: '24px',
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: muted ? 'rgba(255,255,255,0.15)' : 'rgba(224,177,241,0.35)',
                }
              }}
            >
              {iconName && getIconComponent(iconName)}
              {attribute
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </Box>
          </Grid>
        );
      })}
      {sortedAttributes.length > maxAttributes && (
        <Grid item>
          <Box
            component='span'
            sx={{
              color: '#FFFFFF',
              fontSize: '0.7rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '24px',
            }}
          >
            + {sortedAttributes.length - maxAttributes} more
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Attributes;
