'use client';

import { useState, useEffect, useMemo, memo, useRef } from 'react';
import Image from 'next/image';
import { getOptimizedImageUrl } from '../../utils/imageUtils';

/**
 * OptimizedImage component that wraps Next.js Image and applies imgix URL transformation
 * @typedef {Object} OptimizedImageProps
 * @property {string} src - Original image source URL
 * @property {number} [width] - Desired image width (required unless using fill)
 * @property {number} [height] - Desired image height (required unless using fill)
 * @property {number} [quality=75] - Optional image quality (1-100), defaults to 75
 * @property {string} [alt=''] - Image alt text, defaults to empty string
 * @property {boolean} [priority=false] - Whether to prioritize loading, defaults to false
 * @property {Object} [style={}] - Additional style properties
 * @property {string} [className=''] - CSS class name
 * @property {Function} [onLoad] - On load callback function
 * @property {Function} [onError] - On error callback function
 * @property {string} [sizes='100vw'] - Responsive size definitions, defaults to '100vw'
 * @property {string} [objectFit] - Object fit property (now handled via style)
 * @property {string} [objectPosition] - Object position property (now handled via style)
 * @property {boolean} [unoptimized=false] - Whether to skip optimization, defaults to false
 * @property {string} [placeholder='empty'] - Placeholder configuration ('empty', 'blur'), defaults to 'empty'
 * @property {string} [blurDataURL] - Base64 data URL for blur placeholder, required when placeholder is 'blur'
 * @property {boolean} [fill=false] - Whether to fill container, defaults to false. If true, parent must have position: relative
 * @property {boolean} [lazy=false] - Whether to lazy load the image, defaults to false
 * @property {string} [loading] - Directly support Next.js loading prop ('lazy', 'eager')
 * @property {boolean} [isImportant=false] - Whether this image is critical for the venue page LCP
 * @property {boolean} [generateBlurPlaceholder=false] - Whether to auto-generate a blur placeholder
 */

/**
 * OptimizedImage component
 * @param {OptimizedImageProps} props - The component props
 * @returns {JSX.Element} Optimized Image component
 */
function OptimizedImage({
  src,
  width,
  height,
  quality = 75,
  alt = '',
  priority = false,
  style = {},
  className = '',
  onLoad,
  onError,
  sizes = '100vw',
  objectFit, // Deprecated in Next 13+, use style={{ objectFit }}
  objectPosition, // Deprecated in Next 13+, use style={{ objectPosition }}
  unoptimized = false,
  placeholder = 'empty',
  fill = false,
  lazy = false,
  loading,
  blurDataURL,
  isImportant = false, // New prop for critical venue page images
  generateBlurPlaceholder = false, // New prop for auto-generating blur placeholders
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [generatedBlurDataURL, setGeneratedBlurDataURL] = useState('');
  const imgRef = useRef(null);
  const isIntersecting = useRef(false);

  // Determine if this is a critical image - affects loading behavior
  const isCriticalImage = useMemo(() => priority || isImportant, [priority, isImportant]);

  // Determine actual loading behavior based on inputs - memoized to prevent unnecessary recalculations
  const loadingBehavior = useMemo(() => (
    loading || (lazy && !isCriticalImage ? 'lazy' : isCriticalImage ? 'eager' : 'lazy')
  ), [loading, lazy, isCriticalImage]);

  // Enhanced sizes property for responsive images
  const enhancedSizes = useMemo(() => {
    if (sizes !== '100vw') return sizes;
    
    // Create more precise sizes values for better browser rendering
    if (fill) {
      return '(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw';
    }
    
    // If width is provided, use it to calculate relative size
    if (width) {
      const largeScreenSize = Math.min(width, 1200);
      return `(max-width: 640px) 100vw, (max-width: 1024px) 50vw, ${largeScreenSize}px`;
    }
    
    // Fall back to default
    return '100vw';
  }, [sizes, fill, width]);

  // Determine actual placeholder behavior - memoized
  const actualPlaceholder = useMemo(() => {
    if (placeholder === 'blur') {
      return blurDataURL || generatedBlurDataURL ? 'blur' : 'empty';
    }
    return (placeholder === '' || !placeholder) ? 'empty' : placeholder;
  }, [placeholder, blurDataURL, generatedBlurDataURL]);

  // Actual blur data URL to use
  const effectiveBlurDataURL = blurDataURL || generatedBlurDataURL;

  // Combine provided style with deprecated objectFit and objectPosition props - memoized
  const combinedStyle = useMemo(() => {
    const combined = {
      ...style,
      ...(objectFit ? { objectFit } : {}),
      ...(objectPosition ? { objectPosition } : {})
    };

    // Add default objectFit: 'cover' for fill mode if not specified
    if (fill && !combined.objectFit) {
      combined.objectFit = 'cover';
    }

    return combined;
  }, [style, objectFit, objectPosition, fill]);

  // Generate optimized source URL - memoized to avoid recalculation
  const optimizedSrc = useMemo(() => {
    if (!src) return '';
    
    try {
      // Use utility function to transform the URL
      return getOptimizedImageUrl(src, width, quality);
    } catch (err) {
      console.error('Error optimizing image:', err);
      return src; // Fallback to original src
    }
  }, [src, width, quality, unoptimized]);

  // Set loading state when optimizedSrc changes
  useEffect(() => {
    if (!src) {
      setError(new Error('No src provided'));
      setIsLoading(false);
      return;
    }

    if (optimizedSrc) {
      // Reset error state when we have a valid source
      setError(null);
    }
  }, [src, optimizedSrc]);

  // Enhanced onLoad with performance measurements for important images
  const handleLoad = (e) => {
    setIsLoading(false);
    
    // For important images, log performance data
    if (isImportant && performance && performance.mark) {
      const imageId = src.split('/').pop().split('.')[0] || 'image';
      performance.mark(`image-loaded-${imageId}`);
      
      // Report to analytics if available
      if (window.gtag) {
        try {
          const loadTime = performance.now();
          window.gtag('event', 'image_load', {
            event_category: 'performance',
            event_label: `${imageId}`,
            value: Math.round(loadTime)
          });
        } catch (err) {
          // Silently fail if analytics fails
        }
      }
    }
    
    if (onLoad) onLoad(e);
  };

  const handleError = (e) => {
    console.error('Image failed to load:', src);
    console.log('Attempted optimized URL:', optimizedSrc);
    setError(new Error(`Failed to load image: ${src}`));
    setIsLoading(false);
    if (onError) onError(e);
  };

  if (error && !optimizedSrc) {
    return (
      <div 
        className={`image-error ${className}`} 
        style={{ width, height, ...combinedStyle }}
        aria-label={`Error loading image: ${alt}`}
      >
        <div>Image Error</div>
      </div>
    );
  }

  // Fallback image or placeholder for when src is missing
  const imageSrc = optimizedSrc || (placeholder ? undefined : '/img/base_banner.jpeg');
  
  return (
    <Image
      ref={imgRef}
      src={imageSrc}
      width={!fill ? width : undefined}
      height={!fill ? height : undefined}
      alt={alt}
      priority={isCriticalImage}
      style={combinedStyle}
      className={className}
      onLoad={handleLoad}
      onError={handleError}
      sizes={enhancedSizes}
      placeholder={actualPlaceholder === '' ? 'empty' : actualPlaceholder}
      blurDataURL={effectiveBlurDataURL}
      fill={fill}
      loading={loadingBehavior}
      fetchPriority={isCriticalImage ? "high" : "auto"}
      {...rest}
      unoptimized={true}
    />
  );
}

// Export a memoized version of the component to prevent unnecessary re-renders
export default memo(OptimizedImage); 