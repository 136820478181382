'use client';

import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Section, Venue } from '../../../../shared/models';

interface TimeRange {
    start: string; // HH:mm format
    end: string; // HH:mm format
}

interface SectionAvailability {
    id: string;
    name: string;
    timeRanges: TimeRange[];
    color?: string;
}

interface SectionBookedTime {
    id: string;
    timeRanges: TimeRange[];
}

interface CalendarDayProps {
    date: Date;
    isDisabled: boolean;
    isSelected: boolean;
    isCurrentMonth: boolean;
    isPreferred?: boolean;
    onClick: (event: React.MouseEvent) => void;
    section: string; // Section ID to check availability for
    sectionAvailabilities?: SectionAvailability[];
    sectionBookedTimes?: SectionBookedTime[];
    venue?: Venue;
}

const CalendarDay: React.FC<CalendarDayProps> = ({
    date,
    isDisabled,
    isSelected,
    isCurrentMonth,
    isPreferred,
    onClick,
    section,
    sectionAvailabilities = [],
    sectionBookedTimes = [],
    venue,
}) => {
    if (date.toDateString() === new Date('2024-11-28T00:00:00').toDateString()) {
        console.log('sectionBookedTimes', sectionBookedTimes);
    }

    // Check if section has any availability
    const sectionAvailability = section ? sectionAvailabilities.find(s => s.id === section) : sectionAvailabilities[0];
    const hasNoAvailability = !sectionAvailability || sectionAvailability.timeRanges.length === 0;

    // Check if section is partially booked
    const sectionBookedTime = section ? sectionBookedTimes.find(s => s.id === section) : sectionBookedTimes[0];
    const overlappingSectionBookedTimes = sectionBookedTimes.filter(s => {
        // Get the section from venue that matches this booking
        const bookingSection = venue?.Sections?.[s.id];
        return bookingSection?.OverlapsAllOtherSections;
    });

    // Normalize time ranges to handle bookings that go past midnight
    const normalizeTimeRanges = (timeRanges: TimeRange[]) => {
        return timeRanges.map(range => {
            const startTime = new Date(`1970-01-01T${range.start}`);
            let endTime = new Date(`1970-01-01T${range.end}`);

            // If end time is before start time, assume it's the next day
            if (endTime < startTime) {
                endTime = new Date(`1970-01-02T${range.end}`);
            }

            return {
                start: range.start,
                end: range.end,
                normalizedStart: startTime,
                normalizedEnd: endTime
            };
        });
    };

    const allBookedTimeRanges = [
        ...(sectionBookedTime?.timeRanges ? normalizeTimeRanges(sectionBookedTime.timeRanges) : []),
        ...overlappingSectionBookedTimes.flatMap(s => normalizeTimeRanges(s.timeRanges))
    ];

    const isPartiallyBooked = allBookedTimeRanges.length > 0 &&
        sectionAvailability && allBookedTimeRanges.length < sectionAvailability.timeRanges.length;

    // Check if fully booked
    const isFullyBooked = sectionAvailability && sectionBookedTime && (
        // Check if there's a booking for the entire day (same start and end time at 00:00)
        (sectionBookedTime.timeRanges.length > 0 && sectionBookedTime.timeRanges.some(range => range.start === "00:00" && range.end === "00:00")) ||
        // Otherwise check for available blocks
        !sectionAvailability.timeRanges.some(availRange => {
            // If the available range is for the full day (00:00-00:00), then it's not fully booked
            if (availRange.start === availRange.end) {
                return true;
            }

            const availStartTime = new Date(`1970-01-01T${availRange.start}`);
            const availEndTime = new Date(`1970-01-01T${availRange.end}`);
            if (availEndTime < availStartTime) {
                availEndTime.setDate(availEndTime.getDate() + 1);
            }

            const minimumDuration = venue?.PricingRatesPerSection[section]?.MinimumBookingDuration || 0;

            // Get all booked ranges that overlap with this available range
            const overlappingBookings = sectionBookedTime.timeRanges.filter(bookedRange => {
                const bookedStartTime = new Date(`1970-01-01T${bookedRange.start}`);
                const bookedEndTime = new Date(`1970-01-01T${bookedRange.end}`);
                if (bookedEndTime < bookedStartTime) {
                    bookedEndTime.setDate(bookedEndTime.getDate() + 1);
                }
                return bookedStartTime < availEndTime && bookedEndTime > availStartTime;
            });

            // Sort bookings by start time
            overlappingBookings.sort((a, b) => {
                return new Date(`1970-01-01T${a.start}`).getTime() - new Date(`1970-01-01T${b.start}`).getTime();
            });

            // Check for consecutive available blocks
            let currentStart = availStartTime;
            for (let i = 0; i <= overlappingBookings.length; i++) {
                const currentEnd = i < overlappingBookings.length
                    ? new Date(`1970-01-01T${overlappingBookings[i].start}`)
                    : availEndTime;

                if (currentEnd < currentStart) {
                    currentEnd.setDate(currentEnd.getDate() + 1);
                }

                const availableHours = (currentEnd.getTime() - currentStart.getTime()) / (1000 * 60 * 60);

                if (availableHours >= minimumDuration) {
                    return true; // Found a block large enough
                }

                if (i < overlappingBookings.length) {
                    currentStart = new Date(`1970-01-01T${overlappingBookings[i].end}`);
                    if (currentStart < availStartTime) {
                        currentStart.setDate(currentStart.getDate() + 1);
                    }
                }
            }

            return false; // No block large enough found
        })
    );

    const handleInteraction = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault(); // Prevent default touch behavior
        if (hasNoAvailability || isFullyBooked || isDisabled) return;
        if (e.type === 'touchend') {
            // Handle touch event
            const touchEvent = e as React.TouchEvent;
            // Create a synthetic mouse event with the touch coordinates
            const mouseEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
                clientX: touchEvent.changedTouches[0].clientX,
                clientY: touchEvent.changedTouches[0].clientY
            });
            onClick(mouseEvent as any);
        } else {
            // Handle mouse event directly
            onClick(e as React.MouseEvent);
        }
    };

    return (
        <Box
            onClick={handleInteraction}
            onTouchEnd={handleInteraction}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: {
                    xs: 0.5,
                    sm: 1
                },
                position: 'relative',
                cursor: hasNoAvailability || isDisabled || isFullyBooked ? 'not-allowed' : 'pointer',
                bgcolor: isFullyBooked ? 'rgba(255,0,0,0.05)' : 'rgba(255,255,255,0.1)',
                boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                color: !isCurrentMonth ? 'rgba(255,255,255,0.4)' : '#FFFFFF',
                opacity: hasNoAvailability ? 0.3 : isPartiallyBooked ? 0.7 : isDisabled ? 0.4 : 1,
                width: {
                    sm: 'auto'
                },
                minHeight: {
                    xs: `${32 + (sectionAvailabilities.length * 8)}px`
                },
                border: isSelected ? '2px solid #F0D1FF' : 'none',
                WebkitTapHighlightColor: 'transparent',
                touchAction: 'none', // Prevent browser handling of touch events
                WebkitTouchCallout: 'none', // Disable callout on long press
                '&:active': {
                    transform: 'scale(0.98)', // Subtle feedback on press
                    transition: 'transform 0.1s ease-in-out',
                },
                userSelect: 'none',

                // Remove all transitions and hover effects
                ...(isFullyBooked && {
                    backgroundImage: `repeating-linear-gradient(
                        -45deg,
                        rgba(255, 0, 0, 0.05),
                        rgba(255, 0, 0, 0.05) 10px,
                        rgba(255, 0, 0, 0.1) 10px,
                        rgba(255, 0, 0, 0.1) 20px
                    )`,
                    border: '1px solid rgba(255,0,0,0.2)'
                })
            }}
            role="button"
            tabIndex={hasNoAvailability || isFullyBooked ? -1 : 0}
        >
            <Typography
                variant="body2"
                sx={{
                    fontWeight: isSelected ? 600 : 400,
                    fontSize: {
                        xs: '0.75rem',
                        sm: '0.875rem'
                    },
                    fontFamily: 'Sans',
                    textDecoration: isFullyBooked ? 'line-through' : 'none',
                    opacity: isFullyBooked ? 0.6 : 1
                }}
            >
                {date.getDate()}
            </Typography>

            {sectionAvailability && !isFullyBooked && (
                <Stack
                    spacing={0}
                    sx={{
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    {[sectionAvailability].map((section, idx) => {
                        const bookedTimes = sectionBookedTimes.find(s => s.id === section.id)?.timeRanges || [];
                        return (
                            <Box
                                key={section.id}
                                sx={{
                                    width: '100%',
                                    height: {
                                        xs: '5px',
                                        sm: '6px'
                                    },
                                    position: 'relative',
                                    bgcolor: 'transparent',
                                    transition: 'all 0.2s ease-in-out',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        bgcolor: 'rgba(200,200,200,0.3)', // Gray base bar
                                        borderRadius: '2px'
                                    }}
                                >
                                    {bookedTimes
                                        .sort((a, b) => {
                                            const aStart = new Date(`1970-01-01T${a.start}`);
                                            const bStart = new Date(`1970-01-01T${b.start}`);
                                            return aStart.getTime() - bStart.getTime();
                                        })
                                        .reduce((merged, current) => {
                                            const currentStart = new Date(`1970-01-01T${current.start}`);
                                            let currentEnd = new Date(`1970-01-01T${current.end}`);

                                            // Handle times that span past midnight
                                            if (currentEnd < currentStart) {
                                                currentEnd.setDate(currentEnd.getDate() + 1);
                                            }

                                            const last = merged[merged.length - 1];
                                            if (last) {
                                                let lastEnd = new Date(`1970-01-01T${last.end}`);
                                                if (lastEnd < new Date(`1970-01-01T${last.start}`)) {
                                                    lastEnd.setDate(lastEnd.getDate() + 1);
                                                }

                                                if (currentStart <= lastEnd) {
                                                    // Merge overlapping times
                                                    last.end = currentEnd > lastEnd ? current.end : last.end;
                                                    return merged;
                                                }
                                            }
                                            merged.push({ ...current });
                                            return merged;
                                        }, [] as typeof bookedTimes)
                                        .map((bookedTime, index) => {
                                            // Convert times to percentages for positioning
                                            const startTime = new Date(`1970-01-01T${bookedTime.start}`);
                                            let endTime = new Date(`1970-01-01T${bookedTime.end}`);

                                            // Handle times that span past midnight
                                            if (endTime < startTime) {
                                                endTime.setDate(endTime.getDate() + 1);
                                            }

                                            // Get venue hours from section availability
                                            const firstTimeRange = sectionAvailability.timeRanges[0];
                                            const venueStart = new Date(`1970-01-01T${firstTimeRange.start}`);
                                            let venueEnd = new Date(`1970-01-01T${firstTimeRange.end}`);
                                            if (venueEnd < venueStart) {
                                                venueEnd.setDate(venueEnd.getDate() + 1);
                                            }
                                            const venueSpan = venueEnd.getTime() - venueStart.getTime();

                                            const startPercent = Math.max(0, Math.min(100, ((startTime.getTime() - venueStart.getTime()) / venueSpan) * 100));
                                            const endPercent = Math.max(0, Math.min(100, ((endTime.getTime() - venueStart.getTime()) / venueSpan) * 100));
                                            const duration = endPercent - startPercent;

                                            return (
                                                <Box
                                                    key={`booked-${index}`}
                                                    sx={{
                                                        position: 'absolute',
                                                        left: `${startPercent}%`,
                                                        width: `${duration}%`,
                                                        height: '100%',
                                                        bgcolor: '#FF0000',
                                                        zIndex: 2,
                                                        backgroundImage: `repeating-linear-gradient(
                                                            -45deg,
                                                            #FF0000,
                                                            #FF0000 3px,
                                                            #FF3333 3px,
                                                            #FF3333 6px
                                                        )`,
                                                        borderRadius: '2px',
                                                        overflow: 'hidden',
                                                        border: '0.5px solid rgba(255,0,0,0.4)'
                                                    }}
                                                />
                                            );
                                        })}
                                </Box>
                                {bookedTimes.length > 0 && (
                                    <Box
                                        className="tooltip"
                                        sx={{
                                            position: 'absolute',
                                            bottom: '100%',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            bgcolor: 'rgba(0, 0, 0, 0.8)',
                                            color: 'white',
                                            padding: '4px 8px',
                                            borderRadius: '4px',
                                            fontSize: '11px',
                                            fontFamily: 'Circular, -apple-system, sans-serif',
                                            whiteSpace: 'nowrap',
                                            opacity: 0,
                                            visibility: 'hidden',
                                            transition: 'all 0.15s ease-in-out',
                                            zIndex: 10,
                                            marginBottom: '6px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
                                            '&::after': {
                                                content: '""',
                                                position: 'absolute',
                                                top: '100%',
                                                left: '50%',
                                                transform: 'translateX(-50%)',
                                                borderWidth: '4px',
                                                borderStyle: 'solid',
                                                borderColor: 'rgba(0, 0, 0, 0.8) transparent transparent transparent'
                                            }
                                        }}
                                    >
                                        Booked: {bookedTimes.map(range => {
                                            const startTime = new Date(`1970-01-01T${range.start}`);
                                            const endTime = new Date(`1970-01-01T${range.end}`);
                                            return `${startTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })} - ${endTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}`;
                                        }).join(', ')}
                                    </Box>
                                )}
                            </Box >
                        );
                    })}
                </Stack >
            )}
        </Box >
    );
};

export default CalendarDay;
